@font-face {
  font-family: "Great Vibes";
  src: url(./assets/fonts/GreatVibes-Regular.ttf);
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/RobotoCondensed-Light.ttf);
}

* {
  margin: 0;
  padding: 0;
  /* border: 1px solid red; */
}

body {
  font-family: "Great Vibes";
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

/* HEADER */

h2 {
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.8rem;
  margin: auto;
}

.menu {
  display: grid;
  grid-template-columns: 20% 80%;
  text-align: center;
  background: #75664a;
  min-height: 80px;
  width: 100%;
  opacity: 0.9;
  position: fixed;
  z-index: 1000;
}

.menu a {
  font-size: 2.4rem;
  font-weight: bold;
  color: black;
  text-decoration: none;
  margin: auto;
  transition: 0.6s;
}

.menu a:hover {
  color: white;
}

.menu img {
  width: 40%;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: auto;
}

.navigation-menu {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.trigger-3pp-menu {
  display: none;
}

/**** MAIN ****/

/* Description */

.home-title {
  background: url(./assets/images/domain_sky_view.png) center/cover;
  background-color: rgba(255, 255, 255, 0.3);
  background-blend-mode: overlay;
  min-height: 500px;
  color: black;
}

.home-title a {
  font-size: 2.4rem;
  font-weight: bold;
  color: black;
  text-decoration: none;
  margin: auto;
  transition: 0.6s;
}

.home-title a:hover {
  color: white;
}

.home-title h1 {
  font-size: 4rem;
  text-align: center;
  padding: 200px 0 0 0;
  letter-spacing: 4px;
  /*text-transform: uppercase;*/
}

.ecurie-title {
  background: url(./assets/images/ecurie_building_photo.jpg) center/cover;
  background-color: rgba(255, 255, 255, 0.3);
  background-blend-mode: overlay;
  min-height: 500px;
  color: black;
}

.ecurie-title h1 {
  font-size: 4rem;
  text-align: center;
  padding: 250px 0 0 0;
  letter-spacing: 4px;
  /*text-transform: uppercase;*/
}

.hippodrome-title {
  background: url(./assets/images/hippodrome_outside_photo.jpg) center/cover;
  background-color: rgba(255, 255, 255, 0.3);
  background-blend-mode: overlay;
  min-height: 500px;
  color: black;
}

.hippodrome-title h1 {
  font-size: 4rem;
  text-align: center;
  padding: 250px 0 0 0;
  letter-spacing: 4px;
  /*text-transform: uppercase;*/
}

.prices-title {
  background: url(./assets/images/mariage_table_photo.png) center/cover;
  background-color: rgba(255, 255, 255, 0.3);
  background-blend-mode: overlay;
  min-height: 500px;
  color: black;
}

.prices-title h1 {
  font-size: 4rem;
  text-align: center;
  padding: 250px 0 0 0;
  letter-spacing: 4px;
  /*text-transform: uppercase;*/
}

.access-title h1 {
  font-size: 4rem;
  font-weight: normal;
  text-align: center;
  padding: 60px 0 60px 0;
  letter-spacing: 4px;
}

.location {
  display: flex;
  justify-content: center;
  align-items: center;
}

.location a {
  font-size: 3.4rem;
  margin: 0;
}

.location img {
  width: 5%;
}

/* Evènements */

.events {
  display: flex;
  justify-content: space-around;
  font-family: "Roboto";
  margin: 100px 100px 0 100px;
}

.event-item {
  margin: 0 15px 0 15px;
  max-width: 450px;
}

.events img {
  width: 100%;
  height: auto;
}

.event-title {
  font-size: 1.3rem;
  letter-spacing: 5px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 10px 0;
}

/* Avantages */

.advantages {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  margin: 100px 350px 100px 350px;
  font-family: "Roboto";
}

.advantages img {
  width: 18%;
  margin: 0 auto;
}

.advantages-item {
  display: block;
  margin: 0 10px;
  text-align: center;
  padding: 5px;
}

.event-subtitle {
  font-size: 1.1rem;
  color: #616355;
  font-weight: bold;
  margin: 10px 0;
}

/* Bloc de résumé */

.summary-block {
  font-family: "Roboto";
  font-size: 1.2rem;
  padding: 100px 280px 100px 280px;
}

.summary-block h4 {
  display: block;
  font-size: 1.8rem;
  letter-spacing: 5px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 30px;
}

.summary-block span {
  display: block;
  margin: 10px 0;
}

/* Bloc d'information */

.information-block {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  padding: 100px 250px 100px 250px;
  font-family: "Roboto";
}

.information-block-title {
  font-size: 1.8rem;
  letter-spacing: 5px;
  font-weight: bold;
  text-transform: uppercase;
}

.information-block img {
  width: 45%;
}

.information-block p {
  text-align: justify;
  max-width: 50%;
}

.information-block span {
  display: inline-block;
  margin: 10px 0;
}

.information-block a {
  font-weight: bold;
  color: black;
  text-decoration: underline;
  margin: auto;
  transition: 0.6s;
}

.information-block a:hover {
  color: white;
}

.left-separator {
  padding-left: 40px;
}

.right-separator {
  padding-right: 40px;
}

/* Avis clients */

.customer-feedback-title {
  display: flex;
  justify-content: center;
  padding: 100px 150px 0 150px;
}

.customer-feedback-title img {
  max-height: 10vh;
}

.customer-feedback-title p {
  font-size: 1.3rem;
  letter-spacing: 5px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Roboto";
  padding: 0 80px 0 80px;
  margin-top: auto;
  margin-bottom: auto;
}

.customer-feedback {
  padding: 20px 300px 100px 300px;
}

.customer-feedback img {
  width: 100%;
}

/* Offres */

.offers-line {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  text-align: center;
  padding: 100px 50px 20px 50px;
  font-size: 2.5rem;
}

.offers-line-title {
  display: block;
  padding-bottom: 20px;
}

.offer-block {
  padding: 0 10px 0 10px;
}

.offer-description {
  display: grid;
  grid-template-columns: 50% 50%;
  font-family: "Roboto";
  text-align: center;
  font-size: 1.2rem;
}

.offer-description img {
  width: 100%;
}

.offer-description p {
  margin: auto;
  text-align: left;
  padding-left: 4%;
}

/* Accès */

.iframe-map {
  width: 100%;
  min-height: 500px;
  padding-top: 90px;
}

.access-block {
  font-family: "Roboto";
  text-align: center;
  padding: 0 200px 60px 200px;
}

.access-block-title {
  font-size: 1.8rem;
  justify-content: center;
  font-weight: bold;
}

.access-block-title img {
  max-block-size: 30px;
  vertical-align: middle;
  padding-right: 10px;
}

.access-block-detail {
  font-size: 1.1rem;
  padding-top: 20px;
}

/**** FOOTER ****/

footer {
  background-color: black;
  color: white;
  font-size: 1.5rem;
  padding: 10px 0 10px 0;
  font-family: "Roboto";
}

footer h4 {
  text-align: center;
  font-size: 3rem;
  padding: 10px;
}

footer img {
  max-block-size: 25px;
  vertical-align: middle;
}

.contact-information-line {
  text-align: center;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding-top: 10px;
}

.contact-information-line span {
  padding-left: 10px;
  padding-right: 10px;
}

.contact-information-line br {
  display: none;
}

/**** TRANSVERSE ****/

.legend-section {
  padding: 20px 50px 100px 50px;
  font-size: 1.8rem;
}

.odd-section {
  background-color: rgb(216, 217, 205);
}

.white-border {
  border: 4px solid white;
}

.green-border {
  border: 4px solid rgb(216, 217, 205);
}

/**** MOBILE ADAPTATIONS ****/

@media screen and (max-width: 830px) {

  .menu {
    grid-template-columns: 30% 40% 30%;
    grid-template-rows: 2fr;
    grid-template-areas:
      "img . d1"
      "d2 d2 d2";
  }

  .menu img {
    grid-area: img;
    width: 90%;
  }

  .navigation-menu {
    display: none;
    grid-area: d2;
  }

  .navigation-menu.is-open {
    display: block;
    text-align: left;
  }

  .navigation-menu.is-open a {
    display: block;
    padding-left: 15px;
    padding-bottom: 15px;
  }

  .trigger-3pp-menu {
    display: block;
    grid-area: d1;
    width: 100%;
    padding: 25%;
  }

  .trigger-3pp-menu span {
    display: block;
    width: 60%;
    height: 4px;
    margin: 6px 0;
    background-color: white;

  }

  .location {
    display: none;
  }

  .events {
    display: block;
    margin: 20px 20px 0 20px;
  }

  .event-item {
    display: block;
    margin: auto;
    padding-bottom: 20px;
  }

  .information-block {
    display: block;
    padding: 20px;
    text-align: center;
  }

  .information-block img {
    width: 90%;
  }

  .information-block p {
    margin: auto;
    padding: 0;
    max-width: 100%;
  }

  .information-block-title {
    text-align: center;
  }

  .carousel-block {
    margin: auto;
    max-width: 100%;
  }

  .summary-block {
    padding: 20px;
  }

  .summary-block h4 {
    padding: 0;
  }

  .advantages {
    display: block;
    font-size: 1.1rem;
    margin: 20px;
  }

  .advantages img {
    width: 8%;
  }

  .customer-feedback-title {
    padding: 20px 0 20px 0;
  }

  .customer-feedback-title p {
    padding: 0 10px;
  }

  .customer-feedback {
    padding: 20px 0 20px 0;
  }

  .offers-line {
    display: block;
    padding: 20px;
  }

  .offer-block {
    padding: 20px;
  }

  .legend-section {
    text-align: center;
    padding: 0 20px 20px 20px;
  }

  .access-title h1 {
    padding: 20px;
  }

  .access-block {
    display: block;
    padding: 20px;
  }

  .contact-information-line {
    font-size: 1.1rem;
  }

  .contact-information-line img {
    display: none;
  }

  .contact-information-line span {
    padding: 0 2px;
  }

  .contact-information-line br {
    display: block;
  }

}